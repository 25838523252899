const dev = {
    WEB_V2_URL: "https://dev.web.pdax.ph",
};

const test = {
    WEB_V2_URL: "https://test.web.pdax.ph",
};

const stage = {
    WEB_V2_URL: "https://stage.web.pdax.ph",
};

const prod = {
    WEB_V2_URL: "https://web.pdax.ph",
};

const env = () => {
    switch (process.env.REACT_APP_ENV) {
        case "dev":
        case "Dev":
        case "develop":
        case "Develop":
            return dev;
        case "test":
        case "Test":
            return test;
        case "stage":
        case "Stage":
            return stage;
        case "prod":
        case "Prod":
        case "production":
        case "Production":
            return prod;
        default:
            return dev;
    }
};

export default env();
