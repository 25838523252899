import "./AppWebV2Redirect.scss";

import DefaultBg from "./images/cover.svg";
import PdaxBanner from "./images/pdax_logo.svg";
import React from "react";
import Verification from "./images/verification.svg";
import env from "./config/env";

const AppWebV2Redirect = () => (
    <div className="webv2-redirection-container">
        <div className="header">
            <img src={PdaxBanner} alt="PDAX" width={139} />
        </div>
        <div
            className="description"
            style={{ backgroundImage: `url(${DefaultBg})` }}
        >
            <img src={Verification} alt="KYC" width={112} />
            <p>
                Online Account Verification
                <br />
                is now in our new exchange
            </p>
        </div>
        <p className="instruction">
            Go to our new web exchange to start your verification process.
        </p>
        <div className="action-buttons">
            <button
                className="goToButton"
                onClick={() => {
                    window.open(env.WEB_V2_URL + `/kyc`, "_blank").focus();
                    window.close();
                }}
            >
                Go to the new exchange
            </button>
            <button className="closeButton" onClick={() => window.close()}>
                Close
            </button>
        </div>
    </div>
);

export default AppWebV2Redirect;
